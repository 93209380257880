<template>
  <without-header-layout>
    <div class="secondary-page-inner">
      <button @click="goBack">
        <icon icon-name="close-white-button-icon" style="top: 20px; right: 20px; position: absolute"/>
      </button>
      <img :src="info.cover" class="banner-image">
      <div class="about-content-wrapper">
        <h2 class="title">{{ info.title }}</h2>
        <div class="editor-custom-style" v-html="info.content">
        </div>
        <a v-if="info.isExternalLink" :href="`${info.externalLink}?token=${encToken}&Hb_token=${hbToken}`" class="primary-btn bottom-page-btn">
          {{$t('getAPolicy')}}
        </a>
        <button v-else @click="openForm" class="primary-btn bottom-page-btn">
          {{$t('getAPolicy')}}
        </button>
      </div>
    </div>
  </without-header-layout>
</template>

<script>
import WithoutHeaderLayout from "../layouts/WithoutHeaderLayout";
import CloseButtonIcon from "../components/Icons/CloseButtonIcon";
import {mapGetters} from "vuex";
export default {
  name: "AboutProduct",
  components: {CloseButtonIcon, WithoutHeaderLayout},
  data() {
    return {
      info: [],
    }
  },
  mounted() {
    this.getProduct();
  },
  computed:{
    ...mapGetters({
      authStatus: 'status'
    }),
    toHomePage() {
      return Boolean(this.$route.query.toHome)
    },
    encToken() {
      return this.$store.getters['auth/enc'] || localStorage.getItem('enc')
    },

    hbToken() {
      return this.$store.getters.homebankToken
    }
  },
  methods: {
    goBack(){
      if(this.toHomePage) {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    },
    async getProduct() {
      const {data} = await window.axios.get(`/insurance-products/${this.$route.params.id}`);
      this.info = data;
    },
    openForm() {
      let route =  this.$router.resolve({name: 'insurance.products', params: {id: this.info.id, form_component: this.info.form_component, }});
      let baseUrl = this.$store.getters.appUrl + route.href;

      if (!this.authStatus && route.resolved.meta?.requiresAuth && this.$store.getters.isNewVersionHB) {
        baseUrl = `${this.$store.getters.hbUrl}${baseUrl}`
      }

      location.href = baseUrl
    }
  },
}
</script>

<style lang="scss">
  .about-content-wrapper{
    padding: 16px;

    .title{
      font-size: 28px;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
    }
    .editor-custom-style{
      .tag{
        border-radius: 10px;
        padding: 4px 12px 4px 12px;
        width: fit-content;
        display: inline-block;
        margin: 5px 5px 5px 0;
      }
      .list {
        padding: 16px;
        list-style: disc !important;
      }
      a {
        color: #0000EE;
        cursor: pointer;
      }
    }
  }

  .banner-image {
    width: 100% !important;
    /*height: 250px;*/
    object-fit: cover;
    object-position: top;
  }
  .text-section a {
    color: #007f5e;
  }
  .text-section * {
    font-size: 18px !important;
  }
  .main-title {
    font-size: 20px;
    font-weight: 600;
  }


</style>
